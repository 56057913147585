.MainDash{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.MainDash>h2{
    margin-top: 1rem;
    text-align: center;
}
.MainDash>h1{
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 1200px) {
     .Maindash{
        justify-content: flex-start;
        margin-top: 2rem;
    }
}


@media screen and(max-width:768px) {
    .MainDash{
        align-items: center;
    }
}